header.ant-layout-header .logo {
    width: 152px;
    height: 31px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 16px 24px 16px 0;
    float: left;
}

.ant-picker-calendar-full {
    font-size:11px;
}


.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 400px;
}

.removeUser {
    color: red;
}

